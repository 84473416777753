<template>
  <div>
    <button style="color: #f1266d" @click="getLocation">Get Location</button>
    <p v-if="locationError">{{ locationError }}</p>
    <p v-else-if="userLocation">
      Your location is: Latitude: {{ userLocation.latitude }}, Longitude:
      {{ userLocation.longitude }}
    </p>
  </div>
</template>

<script>
export default {
  name: "sjsjJS",
  data() {
    return {
      userLocation: null,
      locationError: null,
    };
  },
  methods: {
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.showPosition,
          this.handleLocationError,
          { enableHighAccuracy: true }
        );
      } else {
        this.locationError = "Geolocation is not supported by this browser.";
      }
    },
    showPosition(position) {
      this.userLocation = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };
    },
    handleLocationError(error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          this.locationError = "User denied the request for Geolocation.";
          break;
        case error.POSITION_UNAVAILABLE:
          this.locationError = "Location information is unavailable.";
          break;
        case error.TIMEOUT:
          this.locationError = "The request to get user location timed out.";
          break;
        case error.UNKNOWN_ERROR:
          this.locationError = "An unknown error occurred.";
          break;
      }
    },
  },
};
</script>
